@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    h1 {
        @apply text-3xl;
        @apply font-bold;
    }

    h2 {
        @apply text-2xl;
        @apply font-bold;
    }

    h3 {
        @apply text-lg;
        @apply font-bold;
    }

    a {
        @apply text-blue-600 underline;
    }

    hr {
        @apply h-px my-8 bg-gray-200 border-0 dark:bg-gray-700;
    }
}

